<template>
  <app-dialog @close="$emit('close')">
    <template #title>
      Choose Instrument
    </template>
    <div class="primary darken-3 pa-2" style="min-height: 95vh;">
      <div class="text-center">
        <v-radio-group row dense hide-details
                       style="display: inline-block"
                       class="justify-center text-capitalize"
                       v-model="type">
          <v-radio color="accent"
                   v-for="(name, i) in Object.keys(types)"
                   :key="i"
                   :label="name"
                   :value="i"/>
        </v-radio-group>
      </div>
      <div style="min-height: 65vh">
        <v-row dense justify="center" align="center">
          <v-col :cols="6" v-for="ins in Object.values(types)[type]" :key="ins">
            <v-btn small block class="ma-1"
                   @click="update(ins)"
                   :color="getMainPlayerInstrument!==ins ? 'primary darken-2':'success'">
              <span class="text-capitalize">
                {{ ins }}
              </span>
            </v-btn>
          </v-col>
        </v-row>
        <!--                <v-col :cols="12">-->
        <!--                  <v-divider class="pb-2"/>-->
        <!--                </v-col>-->
        <!--                <v-col :cols="12" v-if="tab">-->
        <!--                  Type:-->
        <!--                  <v-menu offset-y>-->
        <!--                    <template #activator="{ on }">-->
        <!--                      <v-btn-->
        <!--                          color="primary"-->
        <!--                          dark-->
        <!--                          v-on="on"-->
        <!--                      >-->
        <!--                        {{oscType}}-->
        <!--                      </v-btn>-->
        <!--                    </template>-->
        <!--                    <v-list>-->
        <!--                      <v-list-item-->
        <!--                          v-for="item in types"-->
        <!--                          :key="item"-->
        <!--                          @click=""-->
        <!--                      >-->
        <!--                        <v-list-item-title>{{ item }}</v-list-item-title>-->
        <!--                      </v-list-item>-->
        <!--                    </v-list>-->
        <!--                  </v-menu>-->
        <!--                </v-col>-->
      </div>
      <div class="text-center primary darken-3">
        <app-piano/>
      </div>
    </div>
  </app-dialog>
</template>

<script>
import { mapActions, mapGetters }                  from 'vuex'
import { AVAILABLE_INSTRUMENTS, AVAILABLE_SYNTHS } from 'framework/resources/framework-constants'
import UsageCounters                               from 'reporting/UsageCounters'
import { playNotes }                               from 'framework/helpers/audio-playing/play-notes'
import AppPiano                                    from 'framework/ui/components/piano/AppPiano'

export default {
  name: 'SelectInstrument',

  components: {
    AppPiano,
    AppDialog: () => import(/* webpackChunkName: "app-main" */ 'framework/ui/components/AppDialog'),
  },

  props: {
    player: { default: 0 },
  },

  data() {
    return {
      type:  0,
      types: { Instruments: AVAILABLE_INSTRUMENTS, Synths: AVAILABLE_SYNTHS },
      playNotes,
      // types: ['sine', 'triangle', 'square', 'sawtooth'],
    }
  },

  computed: {
    ...mapGetters('players', ['getMainPlayerInstrument', 'getMainPlayerID']),

    // oscType: function () {
    //   return this.lead.handler ? this.lead.handler.get('oscillator').oscillator.type : 'sine'
    // },
  },

  methods: {
    ...mapActions('players', ['setPlayerInstrument']),
    async update(value) {
      ++UsageCounters.times_changed_instrument
      const payload = {
        id: this.getMainPlayerID,
        value,
      }
      await this.setPlayerInstrument(payload)
    },
  },
}
</script>
