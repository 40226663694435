<template>
  <div :class="`piano ${$vuetify.theme.dark ? 'lighten' : 'darken'}-1`">
    <template v-if="getIsMiniPiano">
      <start :octave="4" style="display: inline-block"/>
      <end :octave="4" style="display: inline-block"/>
    </template>
    <template v-else>
      <start :octave="2" style="display: inline-block"/>
      <end :octave="2" style="display: inline-block"/>
      <start :octave="3" style="display: inline-block"/>
      <end :octave="3" style="display: inline-block"/>
      <start :octave="4" style="display: inline-block"/>
      <end :octave="4" style="display: inline-block"/>
      <start :octave="5" style="display: inline-block"/>
      <end :octave="5" style="display: inline-block"/>
      <start :octave="6" style="display: inline-block"/>
      <end :octave="6" style="display: inline-block"/>
    </template>
    <v-btn icon x-small
           :style="{display: 'inline-block'}"
           :color="showOptions ? 'accent' : ''"
           @click="showOptions = !showOptions">
      <app-icon icon="settings"/>
    </v-btn>
    <v-row no-gutters v-if="showOptions">
      <v-col>
        <v-switch dense color="accent" v-model="isMiniPiano" label="Mini Piano" class="text-center" style="display:
        inline-block"/>
      </v-col>
      <v-col>
        <v-slider dense append-icon="$vuetify.icons.aspectRatio" v-model="pianoSize"
                  color="accent"
                  min="1"
                  max="10"
                  step="0.1"
                  type="Number"
                  class="text-center py-4"/>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'AppPiano',

  components: {
    Start: () => import(/* webpackChunkName: "components" */ 'framework/ui/components/piano/PianoOctaveStart'),
    End:   () => import(/* webpackChunkName: "components" */ 'framework/ui/components/piano/PianoOctaveEnd'),
  },

  data() {
    return {
      showOptions: false,
    }
  },

  computed: {
    ...mapGetters('piano-state', ['getIsMiniPiano']),
    isMiniPiano: {
      get() {
        return this.getIsMiniPiano
      },
      set(val) {
        this.$store.dispatch('piano-state/clearPiano')
        this.$store.dispatch('piano-state/setIsMiniPiano', val)
      },
    },
    pianoSize:   {
      get() {
        return this.$store.getters['settings/getPianoKeySizeMultiplier']
      },
      set(val) {
        this.$store.dispatch('settings/setPianoKeySizeMultiplier', val)
      },
    },
  },
}
</script>

<style scoped>
.piano {
  padding: 3px 0 0 1px;
}
</style>
